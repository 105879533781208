<template>
  <v-card>
    <v-card-title class="ml-2 mt-2 mt-2"
      >Visit(s) for {{ patientName }}</v-card-title
    >
    <v-card-text>
      <v-form>
        <v-row v-if="search_results.length > 0">
          <v-col>
            <v-data-table
              item-key="_id"
              :headers="headers"
              :items="search_results"
              loading-text="Loading... Please wait"
              :sort-by="['name']"
            >
              <template v-slot:[`item.dateCreated`]="{ item }">
                {{ formatDateTime(item.dateCreated) }}
              </template>

              <template v-slot:[`item.add`]="{ item }">
                <v-icon
                  title="Review patient visit"
                  color="blue darken-2"
                  @click="
                    $router.push(
                      `/home/patient-form/${item.patientId}/${item._id}`
                    )
                  "
                >
                  mdi-clipboard-edit
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div id="focus"></div>
        <v-row v-if="no_results">
          <v-col>
            <div>
              <strong
                >no records found for this patient
                <em>{{ searchToken }}</em></strong
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import Vue from "vue";

import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";
import dateTimeFormatter from "../../util/dateTimeFormatter";

export default Vue.extend({
  name: "PatientVisits",
  data() {
    return {
      searchType: "1",
      searchToken: "",
      headers: [
        { text: "Date created", value: "dateCreated" },
        { text: "History", value: "reason", filterable: false },
        { text: "", value: "add", filterable: false },
      ],
      search_results: [],
      no_results: false,
      patientName: "",
    };
  },

  props: {
    patientId: String,
  },

  async mounted() {
    const self = this;

    self.no_results = false;

    self.search_results = [];

    try {
      const token = cookieWrapper.getToken(self);

      const config = {
        method: "get",
        url: `/api/patient-form/formsByPatientExt/${self.patientId}?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios(config);
      self.search_results = data.visits;
      self.patientName = data.patientName;

      if (self.search_results.length == 0) {
        self.no_results = true;
      }

      self.$emit("loaded");
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    formatDateTime(value) {
      return dateTimeFormatter.formatWithMask(value, "YYYY-MM-DD hh:mm A");
    },
  },
});
</script>

