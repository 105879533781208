<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row justify="center" align="center">
      <h1 style="margin-bottom: 30px">System audit log</h1>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="10" lg="11">
        <v-card>
          <v-card-title
            ><span class="ml-2 mt-2">Audit log per clinic</span></v-card-title
          >
          <v-card-text>
            <v-select
              v-if="clinics"
              v-model="selectedClinicId"
              item-text="name"
              item-value="id"
              :items="clinics"
              label="Clinic"
              placeholder="select clinic"
              @change="onSelectionChanged"
            >
            </v-select>

            <v-data-table
              v-if="logs"
              hide-default-footer
              :itemsPerPage="itemsPerPage"
              :headers="headers"
              :items="logs"
            >
            </v-data-table>
          </v-card-text> </v-card></v-col
    ></v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import cookieWrapper from "../../auth/cookieWrapper";

export default {
  name: "AuditLog",
  data() {
    return {
      loading: true,
      selectedClinicId: -1,
      clinics: [],
      logs: [],
      itemsPerPage: 20,
      headers: [{ text: "Log message", value: "message" }],
    };
  },

  async mounted() {
    const self = this;

    try {
      const token = cookieWrapper.getToken(self);

      let config = {
        method: "get",
        url: `/api/clinic/list?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let result = await axios(config);
      self.clinics = result.data.clinics;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    async onSelectionChanged() {
      const self = this;

      try {
        const token = cookieWrapper.getToken(self);

        let config = {
          method: "get",
          url: `/api/audit-log/${self.selectedClinicId}?secret_token=${token}`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        let result = await axios(config);
        self.logs = result.data;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>