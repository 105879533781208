<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1>Patient Visits</h1>
    </v-row>

    <v-row justify="center" align="center">
      <v-col md="12" sm="5" lg="12" col="12">
        <PatientVisits
          :patientId="patientId"
          @loaded="onLoaded"
        ></PatientVisits>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import PatientVisits from "../sub/PatientVisits";

export default Vue.extend({
  name: "AdminPatientVisits",
  title: "HU Portal - Patient Visits",
  data() {
    return {
      loading: true,
    };
  },
  props: {
    patientId: String,
  },
  components: { PatientVisits },
  methods: {
    onLoaded() {
      this.loading = false;
    },
  },
});
</script>